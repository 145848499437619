import { createStore } from 'redux';
import rootReducer from '../reducers'; // We'll create this next
import { getDoc, doc, setDoc, getDocsFromCache, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Function to save state to localStorage
const saveState = async (state) => {
    try {
        const uploadJson = { ...state.hideout, ...state.user, items: state.user.items };
        for (const item in uploadJson.items) {
            const itemAmount = uploadJson.items[item];
            if (itemAmount === 0) {
                delete uploadJson.items[item];
            }
        }
        delete uploadJson.username;
        const userDocRef = doc(db, 'user', state.user.username);
        try {
            await updateDoc(userDocRef, uploadJson, { merge: true });
            return;
        } catch (error) {
            await setDoc(userDocRef, uploadJson);
            return;
        }
    } catch (err) {
        // Handle write errors
        console.error('Could not save state', err);
    }
};

// Function to load state from localStorage
// Make sure to mark this function as async
const loadState = async () => {
    try {
        let stationLevels;
        let itemImages;

        try {
            stationLevels = await getDocsFromCache(collection(db, 'station'));
            if (stationLevels.empty) {
                console.log('No cached stations.');
                stationLevels = await getDocs(collection(db, 'station'));
            }
        } catch (error) {
            console.log('error', error);
        }

        try {
            itemImages = await getDocsFromCache(collection(db, 'item'));
            if (itemImages.empty) {
                console.log('No cached items.');
                itemImages = await getDocs(collection(db, 'item'));
            }
        } catch (error) {
            console.log('error', error);
        }

        const username = localStorage.getItem('username'); // Ensure username is stored in localStorage

        if (!username) {
            const stationLevelInfo = stationLevels.docs.map((doc) => {
                return { [doc.id]: doc.data() };
            });

            const itemInfo = itemImages.docs.map((doc) => {
                return { [doc.id]: doc.data().url };
            });

            const itemState = itemInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});

            const stationLevelsState = stationLevelInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});

            return {
                user: {
                    username: '',
                    level: 1,
                    Endurance: 0,
                    Attention: 0,
                    Memory: 0,
                    Health: 0,
                    Vitality: 0,
                    Metabolism: 0,
                    Strength: 0,
                    items: {}, // Changed to an object
                    ableToBeUpgraded: []
                },
                hideout: {
                    'air-filtering-unit': 0,
                    'bitcoin-farm': 0,
                    'booze-generator': 0,
                    'defective-wall': 0,
                    generator: 0,
                    gym: 0,
                    'hall-of-fame': 0,
                    heating: 0,
                    illumination: 0,
                    'intelligence-center': 0,
                    lavatory: 0,
                    library: 0,
                    medstation: 0,
                    'nutrition-unit': 0,
                    'rest-space': 0,
                    'scav-case': 0,
                    security: 0,
                    'shooting-range': 0,
                    'solar-power': 0,
                    stash: 1,
                    vents: 0,
                    'water-collector': 0,
                    'weapon-rack': 0,
                    workbench: 0
                },
                upgrades: stationLevelsState,
                itemImages: itemState
            };
        }

        const userDocRef = doc(db, 'user', username);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && !stationLevels?.empty) {
            // Assuming the state is stored under a 'state' field in the Firestore document
            let json = { ...userDoc.data() };
            const stationLevelInfo = stationLevels.docs.map((doc) => {
                return { [doc.id]: doc.data() };
            });

            const itemInfo = itemImages.docs.map((doc) => {
                return { [doc.id]: doc.data().url };
            });

            const itemState = itemInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});

            const stationLevelsState = stationLevelInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});

            const userState = {
                username,
                level: userDoc.data().level,
                Endurance: userDoc.data().Endurance,
                Attention: userDoc.data().Attention,
                Memory: userDoc.data().Memory,
                Health: userDoc.data().Health,
                Vitality: userDoc.data().Vitality,
                Metabolism: userDoc.data().Metabolism,
                Strength: userDoc.data().Strength,
                items: userDoc.data().items,
                ableToBeUpgraded: userDoc.data().ableToBeUpgraded || []
            };
            delete json.level;
            delete json.Endurance;
            delete json.Attention;
            delete json.Memory;
            delete json.Health;
            delete json.Vitality;
            delete json.Metabolism;
            delete json.Strength;
            delete json.items;
            delete json.ableToBeUpgraded;
            const hideoutState = { ...json };
            return { user: userState, hideout: hideoutState, upgrades: stationLevelsState, itemImages: itemState };
        } else {
            const stationLevelInfo = stationLevels.docs.map((doc) => {
                return { [doc.id]: doc.data() };
            });

            const itemInfo = itemImages.docs.map((doc) => {
                return { [doc.id]: doc.data().url };
            });

            const itemState = itemInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});

            const stationLevelsState = stationLevelInfo.reduce((acc, cur) => {
                return { ...acc, ...cur };
            }, {});
            return {
                user: {
                    username: '',
                    level: 1,
                    Endurance: 0,
                    Attention: 0,
                    Memory: 0,
                    Health: 0,
                    Vitality: 0,
                    Metabolism: 0,
                    Strength: 0,
                    items: {}, // Changed to an object
                    ableToBeUpgraded: []
                },
                hideout: {
                    'air-filtering-unit': 0,
                    'bitcoin-farm': 0,
                    'booze-generator': 0,
                    'defective-wall': 0,
                    generator: 0,
                    gym: 0,
                    'hall-of-fame': 0,
                    heating: 0,
                    illumination: 0,
                    'intelligence-center': 0,
                    lavatory: 0,
                    library: 0,
                    medstation: 0,
                    'nutrition-unit': 0,
                    'rest-space': 0,
                    'scav-case': 0,
                    security: 0,
                    'shooting-range': 0,
                    'solar-power': 0,
                    stash: 1,
                    vents: 0,
                    'water-collector': 0,
                    'weapon-rack': 0,
                    workbench: 0
                },
                upgrades: stationLevelsState,
                itemImages: itemState
            };
        }
    } catch (err) {
        console.error('Could not load state', err);
        return undefined;
    }
};

async function configureStore() {
    const persistedState = await loadState();

    const store = createStore(rootReducer, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
}

export default configureStore;
