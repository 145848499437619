import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react'; // Import lazy and Suspense
import { Provider as ReduxProvider } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import configureStore from 'store';

import themes from 'themes';
import './App.css';

// Lazy load the SignIn component
const SignIn = lazy(() => import('pages/SignIn/SignIn'));
const Home = lazy(() => import('pages/Home/Home'));
const FleeMarketFlip = lazy(() => import('pages/FleeMarketFlip/FleeMarketFlip'));
const BottleneckItems = lazy(() => import('pages/BottleneckItems/BottleneckItems'));
const LookForItems = lazy(() => import('pages/LookForItems/LookForItems'));
const Progress = lazy(() => import('pages/Progress/Progress'));
const MainHeader = lazy(() => import('header/mainHeader'));

function App() {
    const [store, setStore] = useState(null);

    useEffect(() => {
        configureStore().then((store) => {
            setStore(store);
        });
        const root = document.getElementById('root');
        root.style.backgroundColor = themes().palette.background.paper;
    }, []);

    if (!store) {
        return (
            <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <ReduxProvider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <Router>
                        <Suspense
                            fallback={
                                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            }
                        >
                            <MainHeader>
                                <Routes>
                                    <Route path="/signIn" element={<SignIn />} />
                                    <Route path="/" element={<Home />} />
                                    <Route path="/fleeFlip" element={<FleeMarketFlip />} />
                                    <Route path="/bottleneckItems" element={<BottleneckItems />} />
                                    <Route path="/lookForItems" element={<LookForItems />} />
                                    <Route path="/progress" element={<Progress />} />
                                </Routes>
                            </MainHeader>
                        </Suspense>
                    </Router>
                </ThemeProvider>
            </StyledEngineProvider>
        </ReduxProvider>
    );
}

export default App;
