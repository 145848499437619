// Initial state of the user
const initialState = {
    'air-filtering-unit': 0,
    'bitcoin-farm': 0,
    'booze-generator': 0,
    'defective-wall': 0,
    generator: 0,
    gym: 0,
    'hall-of-fame': 0,
    heating: 0,
    illumination: 0,
    'intelligence-center': 0,
    lavatory: 0,
    library: 0,
    medstation: 0,
    'nutrition-unit': 0,
    'rest-space': 0,
    'scav-case': 0,
    security: 0,
    'shooting-range': 0,
    'solar-power': 0,
    stash: 1,
    vents: 0,
    'water-collector': 0,
    'weapon-rack': 0,
    workbench: 0
};

// The reducer function
function hideoutReducer(state = initialState, action) {
    switch (action.type) {
        case 'LEVEL_UP_HIDEOUT':
            return {
                ...state,
                [action.payload]: state[action.payload] + 1
            };
        case 'LEVEL_DOWN_HIDEOUT':
            return {
                ...state,
                [action.payload]: state[action.payload] - 1
            };
        default:
            return state;
    }
}

export default hideoutReducer;
