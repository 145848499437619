import { combineReducers } from 'redux';
import userReducer from './userReducer';
import hideoutReducer from './hideoutReducer';
import stationLevelsReducer from './stationLevelsReducer';
import itemImageReducer from './itemImageReducer';

const rootReducer = combineReducers({
    user: userReducer,
    hideout: hideoutReducer,
    upgrades: stationLevelsReducer,
    itemImages: itemImageReducer
});

export default rootReducer;
