// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyCP5u98TWbdk7k8GtpnSYeGl6OR-TCnlPA',
    authDomain: 'tarkovtracker-16878.firebaseapp.com',
    projectId: 'tarkovtracker-16878',
    storageBucket: 'tarkovtracker-16878.appspot.com',
    messagingSenderId: '432729559967',
    appId: '1:432729559967:web:b065e9594a5ba417c123aa'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
    localCache: persistentLocalCache({
        cacheSizeBytes: 200000000,
        useFetchStreams: true,
        tabManager: persistentMultipleTabManager()
    })
});
