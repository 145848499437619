const initialState = {
    '#FireKlean gun lube': '',
    '6-STEN-140-M military battery': '',
    'Advanced current converter': '',
    'Alkaline cleaner for heat exchangers': '',
    'Analog thermometer': '',
    'Aseptic bandage': '',
    Awl: '',
    'BakeEzy cook book': '',
    Bolts: '',
    'Bottle of Fierce Hatchling moonshine': '',
    'Bottle of OLOLO Multivitamins': '',
    'Bottle of saline solution': '',
    'Bronze lion figurine': '',
    'Bulbex cable cutter': '',
    'Bundle of wires': '',
    'CPU fan': '',
    'Can of Majaica coffee beans': '',
    'Can of thermite': '',
    'Can of white salt': '',
    Capacitors: '',
    'Car battery': '',
    'Cat figurine': '',
    Chainlet: '',
    'Classic matches': '',
    'Construction measuring tape': '',
    'Corrugated hose': '',
    'Crickent lighter': '',
    'DVD drive': '',
    'Damaged hard drive': '',
    Diary: '',
    'Disposable syringe': '',
    Dollars: '',
    'Dry fuel': '',
    'Duct tape': '',
    'Electric drill': '',
    'Electric motor': '',
    'Energy-saving lamp': '',
    'Esmarch tourniquet': '',
    Euros: '',
    'Factory plan map': '',
    'Far-forward GPS Signal Amplifier Unit': '',
    'Fierce Blow sledgehammer': '',
    'Fleece fabric': '',
    'Gas mask air filter': '',
    'Gold skull ring': '',
    'Golden neck chain': '',
    'Golden rooster figurine': '',
    'GreenBat lithium battery': '',
    'Hand drill': '',
    'Horse figurine': '',
    'Hunting matches': '',
    'Insulating tape': '',
    'Intelligence folder': '',
    'KEKTAPE duct tape': '',
    'LEDX Skin Transilluminator': '',
    'Leatherman Multitool': '',
    'Light bulb': '',
    'Lucky Scav Junk box': '',
    Magnet: '',
    'Medical bloodset': '',
    'Medical tools': '',
    'Metal cutting scissors': '',
    'Metal spare parts': '',
    'Military COFDM Wireless Signal Transmitter': '',
    'Military cable': '',
    'Military corrugated tube': '',
    'Military flash drive': '',
    'Military power filter': '',
    'NIXXOR lens': '',
    Ophthalmoscope: '',
    'Pack of nails': '',
    'Pack of screws': '',
    'Pack of sodium bicarbonate': '',
    'Phase control relay': '',
    'Phased array element': '',
    'Pile of meds': '',
    'Pipe grip wrench': '',
    'Pliers Elite': '',
    'Power cord': '',
    'Power supply unit': '',
    'Pressure gauge': '',
    'Printed circuit board': '',
    'Radiator helix': '',
    'Ratchet wrench': '',
    'Roler Submariner gold wrist watch': '',
    Roubles: '',
    'Round pliers': '',
    'SSD drive': '',
    'Screw nuts': '',
    'Secure Flash drive': '',
    'Secure magnetic tape cassette': '',
    'Set of files "Master"': '',
    'Sewing kit': '',
    'Shustrilo sealing foam': '',
    'Silicone tube': '',
    'Slim diary': '',
    'Smoked Chimney drain cleaner': '',
    Soap: '',
    'Spark plug': '',
    'T-Shaped plug': '',
    'TP-200 TNT brick': '',
    'Tech manual': '',
    'Toilet paper': '',
    Toolset: '',
    Toothpaste: '',
    'Topographic survey maps': '',
    'Tube of Poxeram cold welding': '',
    'VPX Flash Storage Module': '',
    'WD-40 (100ml)': '',
    'WI-FI Camera': '',
    'Weapon parts': '',
    'Working LCD': '',
    Wrench: '',
    'Xenomorph sealing foam': ''
};

// The reducer function
function itemImageReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default itemImageReducer;
