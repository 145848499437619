// Initial state of the user
const initialState = {
    username: '',
    level: 1,
    Endurance: 0,
    Attention: 0,
    Memory: 0,
    Health: 0,
    Vitality: 0,
    Metabolism: 0,
    Strength: 0,
    items: {}, // Changed to an object
    ableToBeUpgraded: []
};

// The reducer function
function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_USERNAME':
            return {
                ...state,
                username: action.payload
            };
        case 'CLEAR_USERNAME':
            return {
                ...state,
                username: ''
            };
        case 'LEVEL_UP_PLAYER':
            return {
                ...state,
                [action.payload]: state[action.payload] + 1
            };
        case 'LEVEL_DOWN_PLAYER':
            return {
                ...state,
                [action.payload]: state[action.payload] - 1
            };
        case 'SET_ABLE_TO_BE_UPGRADED':
            return {
                ...state,
                ableToBeUpgraded: action.payload
            };
        case 'SET_ITEM':
            const setItemKey = action.payload.item; // Assume payload is the item's key
            const setItem = {
                ...state.items,
                [setItemKey]: action.payload.amount
            };
            return {
                ...state,
                items: setItem
            };
        case 'ADD_ITEM':
            // Adding an item, ensuring we're correctly updating the state
            const addItemKey = action.payload.item; // Assume payload is the item's key
            const addedItems = {
                ...state.items,
                [addItemKey]: (state.items[addItemKey] || 0) + action.payload.amount
            };
            return {
                ...state,
                items: addedItems
            };
        case 'REMOVE_ITEM':
            // Removing an item, ensuring we're correctly updating the state
            const removeItemKey = action.payload.item; // Assume payload is the item's key
            const removedItems = {
                ...state.items,
                [removeItemKey]: (state.items[removeItemKey] || 0) - action.payload.amount
            };
            return {
                ...state,
                items: removedItems
            };
        default:
            return state;
    }
}

export default userReducer;
