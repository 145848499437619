// Initial state of the user
const initialState = {
    'air-filtering-unit': { levels: [] },
    'bitcoin-farm': { levels: [] },
    'booze-generator': { levels: [] },
    'defective-wall': { levels: [] },
    generator: { levels: [] },
    gym: { levels: [] },
    'hall-of-fame': { levels: [] },
    heating: { levels: [] },
    illumination: { levels: [] },
    'intelligence-center': { levels: [] },
    lavatory: { levels: [] },
    library: { levels: [] },
    medstation: { levels: [] },
    'nutrition-unit': { levels: [] },
    'rest-space': { levels: [] },
    'scav-case': { levels: [] },
    security: { levels: [] },
    'shooting-range': { levels: [] },
    'solar-power': { levels: [] },
    stash: { levels: [] },
    vents: { levels: [] },
    'water-collector': { levels: [] },
    'weapon-rack': { levels: [] },
    workbench: { levels: [] }
};

// The reducer function
function stationLevelsReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default stationLevelsReducer;
